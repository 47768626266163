<template>
  <div class="p-mx-auto p-px-auto" style="min-width: 400px; max-width: 980px">
    <Card>
      <template #content>
        <Accordion :multiple="true">
          <AccordionTab
            v-for="(part, index) in data.parts"
            :key="'part-' + index"
            :header="part.title"
          >
            <div
              v-for="(message, messageIndex) in getFeedbackMessagesFromPart(
                part
              )"
              :key="'part-' + index + '-message-' + messageIndex"
            >
              <div class="p-d-flex p-ai-center p-jc-between">
                <h4 class="trbc-color">
                  {{ message.id + 1 }}. {{ message.text }}
                </h4>
                <Badge
                  style="width: 4em; min-width: 4em"
                  :value="message.value + ' / ' + part.max"
                />
              </div>

              <div class="p-px-4">
                <div v-if="message.feedback.youtubeId != null">
                  <Divider align="left" type="dashed">
                    <div class="p-d-inline-flex p-ai-center">
                      <i class="fab fa-youtube p-mr-2" />
                      <b>Watch</b>
                    </div>
                  </Divider>
                  <div class="video-container">
                    <iframe
                      :src="
                        'https://www.youtube.com/embed/' +
                        message.feedback.youtubeId
                      "
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div v-if="message.feedback.watchExtra.length > 0">
                    <Divider align="left" type="dashed">
                      <div class="p-d-inline-flex p-ai-center">
                        <i class="fab fa-youtube p-mr-2" />
                        <b>Additional Videos</b>
                      </div>
                    </Divider>
                    <a
                      target="_blank"
                      style="font-weight: 500; color: #2196f3"
                      v-for="vid in message.feedback.watchExtra"
                      :key="vid.name"
                      :href="vid.url"
                      >{{ vid.name }}</a
                    >
                  </div>
                </div>

                <div
                  v-if="
                    message.feedback.startBook != null &&
                    message.feedback.endBook != null
                  "
                >
                  <Divider align="left" type="dashed">
                    <div class="p-d-inline-flex p-ai-center">
                      <i class="fas fa-book p-mr-2" />
                      <b>START Book</b>
                    </div>
                  </Divider>
                  <p>
                    Read pages: {{ message.feedback.startBook }} -
                    {{ message.feedback.endBook }}
                  </p>
                  <Button
                    class="p-button-secondary"
                    @click="clickStartReading(message.feedback.realStartBook)"
                  >
                    <i class="fas fa-book-open" />
                    <span class="p-pl-2">START</span>
                  </Button>
                </div>
                <div v-if="message.feedback.readExtra.length > 0">
                  <Divider align="left" type="dashed">
                    <div class="p-d-inline-flex p-ai-center">
                      <i class="fa fa-book p-mr-2" />
                      <b>Additional Readings</b>
                    </div>
                  </Divider>
                  <a
                    v-for="book in message.feedback.readExtra"
                    :key="book.name"
                    :href="book.url"
                    target="_blank"
                    style="font-weight: 500; color: #2196f3"
                    >{{ book.name }}</a
                  >
                </div>

                <div
                  v-if="
                    message.feedback.youtubeId == null &&
                    message.feedback.watchExtra.length > 0
                  "
                >
                  <Divider align="left" type="dashed">
                    <div class="p-d-inline-flex p-ai-center">
                      <i class="fab fa-youtube p-mr-2" />
                      <b>Additional Videos</b>
                    </div>
                  </Divider>
                  <a
                    target="_blank"
                    style="font-weight: 500; color: #2196f3"
                    v-for="vid in message.feedback.watchExtra"
                    :key="vid.name"
                    :href="vid.url"
                    >{{ vid.name }}</a
                  >
                </div>

                <div v-if="message.feedback.url != null">
                  <Divider align="left" type="dashed">
                    <div class="p-d-inline-flex p-ai-center">
                      <i class="fas fa-compass p-mr-2" />
                      <b>Resources</b>
                    </div>
                  </Divider>
                  <Button
                    class="p-button-secondary"
                    @click="clickReadMore(message.feedback)"
                    label="Click Here"
                  />
                </div>
              </div>
              <Divider />
            </div>
          </AccordionTab>
        </Accordion>
      </template>
    </Card>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'SurveyResult',
  props: {
    data: Object
  },
  setup(props) {
    const router = useRouter()

    onMounted(() => {
      if (props.data.parts == null) {
        router.push({
          path: '/surveys/general/'
        })
      }
    })

    const getFeedbackMessagesFromPart = function (part) {
      return part.messages.filter((message) => message.value <= 2)
    }

    const clickReadMore = function (feedback) {
      window.location.href = feedback.url
    }

    const clickStartReading = function (startBook) {
      window.open(
        'https://cdn.trbc.org/wp-content/uploads/start-book-2025.pdf#page=' +
          startBook,
        '_blank'
      )
    }

    return { getFeedbackMessagesFromPart, clickReadMore, clickStartReading }
  }
}
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
